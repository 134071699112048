<template>
  <el-upload
    ref="upload"
    action=""
    :limit="1"
    :auto-upload="false"
    :on-change="changeUpload"
    :on-exceed="handleExceed"
    :show-file-list="false"
    accept=".csv"
    class="upload-form"
  >
    <template #trigger>
      <el-button type="info" plain style="font-weight: normal; border: none">上傳文件</el-button>
    </template>
    <div v-if="available_shop_file_url" class="file-list">
      <div class="item" @click="downloadFile(available_shop_file_url)">
        <el-icon style="font-size: 1.8rem; margin-right: 5px"><Download /></el-icon>
        <span class="file-name">
          {{
            available_shop_file_name.length > 20
              ? `${available_shop_file_name.slice(0, 17)}...`
              : available_shop_file_name
          }}
        </span>
      </div>
      <div class="delete" @click="removeFile()">
        <el-icon><Delete /></el-icon>
      </div>
    </div>
  </el-upload>
</template>
<script>
import { ref, computed } from 'vue';
import { genFileId } from 'element-plus';
import { downloadFile } from '@/utils/download';
import { uploadFile } from '@/api/common';
export default {
  name: 'upload-csv',
  props: ['file_url', 'file_name'],
  emits: ['update:file_url', 'update:file_name'],
  setup(props, context) {
    const upload = ref(null);
    const available_shop_file_url = computed({
      get: () => props.file_url,
      set: (val) => context.emit('update:file_url', val),
    });
    const available_shop_file_name = computed({
      get: () => props.file_name,
      set: (val) => context.emit('update:file_name', val),
    });
    const changeUpload = async (file) => {
      // console.log('changeUpload', file);
      try {
        const base64 = await getBase64(file.raw);
        const res = await uploadFile({ filename: file.name, file: base64.split(',')[1] });
        available_shop_file_url.value = res.url;
        available_shop_file_name.value = file.name;
      } catch (e) {
        upload.value.handleRemove(file);
      }
    };
    const handleExceed = (files) => {
      // console.log('handleExceed', files);
      const file = files[0];
      upload.value.clearFiles();
      file.uid = genFileId();
      upload.value.handleStart(file);
    };
    const removeFile = () => {
      available_shop_file_url.value = null;
      available_shop_file_name.value = null;
    };

    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    return {
      downloadFile,
      upload,
      available_shop_file_url,
      available_shop_file_name,
      changeUpload,
      handleExceed,
      removeFile,
    };
  },
};
</script>
<style lang="scss" scoped>
.upload-form {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
}
.file-list {
  max-width: 310px;
  padding: 0 8px;
  @include flex-between-center;
  cursor: pointer;
  transition: 0.5s;
  background-color: #fff;
  border-radius: 4px;
  &:hover {
    background-color: $color-whiteGray;
  }
  .item {
    display: flex;
    align-items: center;
    .file-name {
      color: $color-green;
      text-decoration: underline;
    }
  }
  .delete {
    transition: 0.2s;
    &:hover {
      color: $color-danger;
    }
  }
}
</style>
